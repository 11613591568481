<template>
  <div class="zengpindetail">
    <van-nav-bar
      title="领取赠品"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-cell center is-link class="checkaddress" @click="checkaddress" v-if="!(address && address.id && address.id > 0)">
        <van-icon slot="icon" name="add-square" size="30px" color="#EE0A24" />
        <template slot="title">选择收货地址</template>
      </van-cell>
      <van-cell center is-link class="checkaddress" @click="checkaddress" v-if="address && address.id && address.id > 0">
        <template slot="title">
          <div>{{address.name}} {{address.mobile}}</div>
          <div>{{address.province}} {{address.city}} {{address.area}} {{address.street}}</div>
        </template>
      </van-cell>
      <div class="goods_main">
        <van-radio-group v-model="checkgoods.id">
          <van-cell class="zegnpin_list" v-for="(item, index) in active.goods" :key="index">
            <template #icon>
              <van-radio icon-size="16px" checked-color="#EE0A24" :name="item.id" @click="checkGoods(item)" />
            </template>
            <template #title>
              <div class="zengpin">
                <van-card
                  class="goods_list"
                  :title="item.goodstitle"
                  :thumb="item.goodsthumb"
                  lazy-load
                  :num="item.num"
                  @click="checkGoods(item)"
                >
                  <template slot="desc">
                    {{item.title}}
                  </template>
                </van-card>
              </div> 
            </template>
          </van-cell>
        </van-radio-group>
      </div>

      <div class="submit_btn">
        <van-button type="danger" block round :loading="loading" @click="addOrder">确认领取</van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ZengPingDetail",
  data () {
    return {
      id: 0, // 活动id
      active: {}, // 活动信息
      checkgoods: {},
      address: {},
      rechageId: 0,
      shareid: 0,
      merchid: 0,
      loading: false,
      islogin: localStorage.getItem("DK_ISLOGIN") ? localStorage.getItem("DK_ISLOGIN") : false,
      iswx: false,
      openid: '',
      newopenid: '',
    }
  },
  mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : 0;
    this.rechageId = this.$route.query.rechageId ? this.$route.query.rechageId : 0;
    this.init();
  },
  activated() {
    if (window.isWeixin()) {
      this.iswx = true;  // 在微信中打开
    }else{
      this.iswx = false; // 不在微信中打开
    }

    this.openid = localStorage.getItem("DK_OPENID");
    
    this.newopenid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : null;
    
    // if (!this.newopenid && this.iswx) {
    //   this.code = this.$route.query.code ? this.$route.query.code : null;
    //   if (this.code) {
    //     this.getwxlog(this.code);
    //   }else {
    //     this.getopenid();
    //   }
    // }

    if (this.islogin && localStorage.getItem("address")) {
      this.address = localStorage.getItem("address");
      let address = null;
      address = JSON.parse(this.address);
      console.log(address, "address");
      if(address.province && (address.province + "").trim()){
        this.address = JSON.parse(this.address);
      }
      
    }
  },
  deactivated() {
    localStorage.removeItem("address");
    this.address = {}
  },
  methods: {
    init() {
      if (this.$route.query.shareid) {
        this.shareid = this.$route.query.shareid
      }else if (localStorage.getItem("DK_MEMBERID")) {
        this.shareid = localStorage.getItem("DK_MEMBERID");
      }
      this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
      this.$parent.getmerchname(this.merchid, "领取赠品");
      this.getOne();
    },

    /**
     * 微信code值
     */
    getopenid() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : false;

      /* 在微信中打开页面 */
      if (!openid && this.iswx) { 
        const wxAuthUrl = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx65fad56bebb47a1e&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";

          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },

    /**
     * 获取微信openID
     */
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_new_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_NEW_OPENID", response.data); // 获得用户的openid
          }
        })
        .catch(error => {
          console.log(error);
        })
    },

    // 获取列表
    getOne() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/recharge/getOne",
          _that.$qs.stringify({
            id: _that.id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.active = res.data.data;
          }
          if (_that.active.goods && _that.active.goods.length == 1) {
            _that.checkgoods = _that.active.goods[0];
          }

          if (_that.active.type == 1) {
            _that.checkgoods = _that.active.goods;
          }
        })
        .catch(err => {
          console(err)
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 选择地址
    checkaddress() {
      this.$router.push({
        path: '/addressList',
        query: { 
          type: "activity", 
          shareid: this.shareid, 
          merchid: this.merchid 
        }
      });
    },
    // 选择商品
    checkGoods(goods) {
      this.checkgoods = goods;
    },
    // 提交保存
    addOrder() {
      console.log(this.checkgoods);
      const goods = [];
      let _that = null;
      _that = this;

      if (_that.active.type == 1) {
        _that.active.goods.forEach(item => {
          goods.push({
            goodsid: item.goodsid,
            optionid: item.id,
            num: item.num,
            merchid: 0,
            message: '赠品领取',
            kongjiapic: ''
          })
        })
      }else {
        goods.push({
          goodsid: _that.checkgoods.goodsid,
          optionid: _that.checkgoods.id,
          num: _that.checkgoods.num,
          merchid: 0,
          message: '赠品领取',
          kongjiapic: ''
        });
      }
      
      const toast = _that.$toast({
        type: 'loading',
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      const DKUID = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers.common["Authorization"] = DKUID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv1/add",
          _that.$qs.stringify({
            goods: goods,
            addressid: _that.address.id,
            "merchid_fake": _that.merchid,
            openid: _that.newopenid, // 参与支付的openID
            mobile: _that.mobile // 虚拟产品必填
          })
        )
        .then(response => {
          console.log(response);
          let orderinfo = null;
          orderinfo = response.data.data;
          if (response.data.code == 100000) {
            _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
            const DKUID = localStorage.getItem("DK_UID");
            _that.$axios.defaults.headers.common["Authorization"] = DKUID;
            _that.$axios
              .post(
                _that.$store.state.domain + "web/recharge/updateOrder",
                _that.$qs.stringify({
                  orderid: orderinfo.oid,
                  rechageId: _that.rechageId
                })
              )
              .then(res => {
                toast.clear();
                if (res.data.code == 100000) {
                  _that.$toast({
                    type: "text",
                    message: "领取成功",
                    forbidClick: true
                  });
                  _that.$router.go(-2);
                }else {
                  _that.$toast(res.data.msg ? res.data.msg : "领取失败");
                }
              })
              .catch(err => {
                console.log(err);
                toast.clear();
                _that.$toast("领取失败")
              })
          }else {
            toast.clear();
            _that.$toast(response.data.msg ? response.data.msg : "领取失败");
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
}
</script>
<style lang="less">
.zengpindetail {
  overflow: hidden;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }

  .checkaddress .van-cell__title {
    margin: 0 10px;
    flex: 1;
    text-align: left;
  }

  .goods_main {
    .van-cell__title {
      flex: 1;
      overflow: hidden;
    }
  }

  .goods_list {
    background-color: #fff;
    text-align: left;
  }
  .card_desc {
    overflow: hidden;
  }
  .check_sku_show {
    padding: 10px 5px;
  }
  .btn_list {
    margin: 40px 10px 0;
  }
  .goods_main {
    margin-top: 10px;
  }
  .zegnpin_list {
    overflow: hidden;
  }
  .submit_btn {
    position: fixed;
    left: 16px;
    right: 16px;
    bottom: 60px;
  }
}
</style>